<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<h5>GENEL</h5>
				<div class="grid">
					<div class="col-4">
						<div class="field p-fluid">
							<label for="baslik">Selected Variable Name</label>
							<InputText id="baslik" type="text" v-model="selectedVariableName" />
						</div>
					</div>
					<div class="col-4">
						<div class="field p-fluid">
							<label for="baslik">Label</label>
							<InputText id="baslik" type="text" v-model="label" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
		
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<div class="col-12">
					<h5>ENTITY LOOKUP</h5>
					<div class="field p-fluid">
						<pre v-code><code>
&lt;div class="col-3"&gt;
	&lt;div class="field p-fluid"&gt;
		&lt;EntityLookup id="{{selectedVariableName}}" v-model="mainData.{{selectedVariableName}}Name" ref="entity_{{selectedVariableName}}"
			label="{{label}}" entityName="bm_servispersonel" nameField="bm_name" :state="true"
			@itemSelected="{{selectedVariableName}}Selected = $event" @itemCleared="{{selectedVariableName}}Selected = null"&gt;
		&lt;/EntityLookup&gt;
	&lt;/div&gt;
&lt;/div&gt;

</code></pre>

						<hr/>
						<pre v-code><code>
{{selectedVariableName}}Selected: {
	get: function () {
		if (this.mainData["{{selectedVariableName}}Id"]) {
			return { "Value": this.mainData["{{selectedVariableName}}Id"], "Name": this.mainData["{{selectedVariableName}}Name"] }
		} else{ return null; }
	},
	set: function(newValue) {
		if (newValue == null) {
			this.mainData["{{selectedVariableName}}Id"] = null;
			this.mainData["{{selectedVariableName}}Name"] = null;
		}
		else {
			this.mainData["{{selectedVariableName}}Id"] = newValue.Value;
			this.mainData["{{selectedVariableName}}Name"] = newValue.Name;
		}
	}
},
</code></pre>
						<hr/>
						<pre v-code><code>
if (this.mainData["{{selectedVariableName}}Id"]) {
	this.$refs.entity_{{selectedVariableName}}.setDefaultData({ "Value": this.mainData["{{selectedVariableName}}Id"], "Name": this.mainData["{{selectedVariableName}}Name"] });
}
</code></pre>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="grid">
		<div class="col-12">
			<div class="card">
				<div class="col-12">
					<h5>DROPDOWN</h5>
					<div class="field p-fluid">
						<pre>
&lt;div class="col-3"&gt;
	&lt;div class="field p-fluid"&gt;
		&lt;label for="{{selectedVariableName}}"&gt;{{label}}&lt;/label&gt;
		&lt;Dropdown id="{{selectedVariableName}}" v-model="mainData.{{selectedVariableName}}" :options="SM_{{selectedVariableName}}" optionLabel="Value" optionValue="AttributeValue" placeholder="{{label}}" :showClear="true" /&gt;
	&lt;/div&gt;
&lt;/div&gt;
						</pre>
						<hr/>
						<pre>
SM_{{selectedVariableName}}: function () {
	return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_{{selectedVariableName.toLowerCase()}}');
},
						</pre>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			selectedVariableName: 'potansiyelMusteri',
			label: 'Potansiyel Müşteri',
		}
	},
}
</script>

<style lang="scss" scoped>
	.tab1 {
		tab-size: 2;
	}

	.tab2 {
		tab-size: 4;
	}

	.tab4 {
		tab-size: 8;
	}
</style>
